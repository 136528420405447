<template>
  <div>
    <coupon-filters
      class="s-mb-3"
      :options="filterOptions"
      v-model="filter"
    ></coupon-filters>

    <Loading v-if="showLoading" loading-message="載入中..." />
    <div v-else-if="!showLoading && filteredCoupons[filter].length > 0">
      <coupon-card
        v-for="item in filteredCoupons[filter]"
        :key="item.id"
        :coupon="item"
        :show-button="false"
      >
      </coupon-card>
    </div>
    <coupon-empty v-else />
  </div>
</template>

<script>
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue";
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue";
import couponApi from '@/apis/liff/v2/coupon'
import Loading from "@/components/Page/Liff/Shared/Loading";

export default {
  components: { CouponFilters, CouponCard, CouponEmpty, Loading },
  data() {
    return {
      showLoading: true,
      filter: "all",
      coupons: []
    };
  },

  computed: {
    filterOptions() {
      return [
        {
          key: "all",
          text: `${"全部 (" + this.coupons.length + ")"}`,
        },
        {
          key: "used",
          text: `${"已使用 (" + this.filteredCoupons.used.length + ")"}`,
        },
        {
          key: "expired",
          text: `${"已過期 (" + this.filteredCoupons.expired.length + ")"}`,
        },
      ];
    },

    filteredCoupons() {
      return {
        all: this.coupons,
        used: this.coupons.filter((c) => c.status === "redeemed"),
        expired: this.coupons.filter((c) => c.status === "expired"),
      };
    },
  },

  mounted() {
    this.fetchCoupon();
  },

  methods: {
    async fetchCoupon() {
      this.showLoading = true
      this.coupons = await couponApi.getCoupons({
        status: 'redeemed_or_expired',
        sort_by: 'redeem_at',
        sec_sort_by: 'belong_at',
      });
      this.coupons.forEach(coupon => {
        coupon.name = coupon?.redeem_code_bundle?.name
      })
      this.showLoading = false
    },
  },
};
</script>

<style></style>
